export const WarrantyCheckDefault = () => (
    <svg className="w-16 h-16 stroke-blue-10*" xmlns="http://www.w3.org/2000/svg" fill="none">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M32 7.238a31.89 31.89 0 0 1-22.405 8.763A31.974 31.974 0 0 0 8 25.998c0 14.912 10.197 27.44 24 30.995C45.803 53.44 56 40.913 56 26c0-3.493-.56-6.856-1.595-10.003H54a31.88 31.88 0 0 1-22-8.76Z" />
    </svg>
);
export const WarrantyShieldFullCoverage = () => (
    <svg className="stroke-green-5* w-16 h-16" xmlns="http://www.w3.org/2000/svg" fill="none">
        <path stroke="#34C759" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="m24 34 6 6 10-14M32 7.239a31.89 31.89 0 0 1-22.405 8.763A31.974 31.974 0 0 0 8 25.998c0 14.912 10.197 27.44 24 30.995C45.803 53.44 56 40.913 56 26c0-3.493-.56-6.856-1.595-10.003H54a31.88 31.88 0 0 1-22-8.76Z"/>
    </svg>
);
export const WarrantyShieldNoCoverage = () => (
    <svg className="stroke-red-6* w-16 h-16" xmlns="http://www.w3.org/2000/svg" fill="none">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M32 7a31.89 31.89 0 0 1-22.405 8.763A31.973 31.973 0 0 0 8 25.76C8 40.672 18.197 53.2 32 56.755c13.803-3.552 24-16.08 24-30.992 0-3.494-.56-6.856-1.595-10.003H54A31.88 31.88 0 0 1 32 7Z"/>
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3.8" d="m24.5 40.5 15-15m-15 0 15 15"/>
    </svg>
);
export const WarrantyShieldPartialCoverage = () => (
    <svg className="stroke-yellow-6 dark:stroke-yellow-5* w-16 h-16" xmlns="http://www.w3.org/2000/svg" fill="none">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M32 7a31.89 31.89 0 0 1-22.405 8.763A31.973 31.973 0 0 0 8 25.76C8 40.672 18.197 53.2 32 56.755c13.803-3.552 24-16.08 24-30.992 0-3.494-.56-6.856-1.595-10.003H54A31.88 31.88 0 0 1 32 7Z"/>
        <path  stroke-linecap="round" stroke-width="3.8" d="M21 32h22"/>
    </svg>
);
export const TooltipIcon = () => (
    <svg className="stroke-neutral-9 dark:stroke-neutral-2 w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"/>
    </svg>
);
export const DetailsDropdown = () => (
    <svg className="w-5 h-5 stroke-blue-10* dark:stroke-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" d="M16.25 6.875 10 13.125l-6.25-6.25"/>
    </svg>
);
export const Lightbulb = () => (
    <svg className="fill-blue-10* dark:fill-yellow-5* w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none">
        <path d="M12 22c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 10 20h4c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 12 22Zm-4-3v-2h8v2H8Zm.25-3a7.662 7.662 0 0 1-2.737-2.75A7.278 7.278 0 0 1 4.5 9.5c0-2.083.73-3.854 2.188-5.313C8.146 2.73 9.916 2 12 2s3.854.73 5.313 2.188C18.77 5.646 19.5 7.417 19.5 9.5c0 1.35-.337 2.6-1.012 3.75A7.662 7.662 0 0 1 15.75 16h-7.5Zm.6-2h6.3a5.548 5.548 0 0 0 1.737-1.975A5.383 5.383 0 0 0 17.5 9.5c0-1.533-.533-2.833-1.6-3.9C14.833 4.533 13.533 4 12 4s-2.833.533-3.9 1.6C7.033 6.667 6.5 7.967 6.5 9.5c0 .9.204 1.742.612 2.525A5.549 5.549 0 0 0 8.85 14Z"/>
    </svg>
);
export const ExternalLink = () => (
    <svg className="h-3 w-3 fill-blue-10* dark:fill-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none">
        <path d="M2.5 10.5a.963.963 0 0 1-.706-.294A.963.963 0 0 1 1.5 9.5v-7c0-.275.098-.51.294-.706A.963.963 0 0 1 2.5 1.5H6v1H2.5v7h7V6h1v3.5c0 .275-.098.51-.294.706a.963.963 0 0 1-.706.294h-7Zm2.35-2.65-.7-.7L8.8 2.5H7v-1h3.5V5h-1V3.2L4.85 7.85Z"/>
    </svg>
);
export const LaptopDevice = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-7 h-5" fill="none"><path fill="#616161" d="M0 16.667V15h3.333v-.833c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.177V4.167c0-.459.164-.851.49-1.177.327-.327.719-.49 1.177-.49h13.334c.458 0 .85.163 1.177.49.326.326.49.718.49 1.177V12.5c0 .458-.164.85-.49 1.177-.327.327-.719.49-1.177.49V15H20v1.667H0ZM3.333 12.5h13.334V4.167H3.333V12.5Z"/></svg>
);
export const SerialNumber = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-neutral-7 dark:fill-neutral-3 stroke-neutral-7 dark:stroke-neutral-3" fill="none"><path d="M4.375 6.875h12.5Zm-1.25 6.25h12.5Zm11-11.25-3.25 16.25Zm-5 0-3.25 16.25Z"/><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" d="M4.375 6.875h12.5m-13.75 6.25h12.5m-1.5-11.25-3.25 16.25m-1.75-16.25-3.25 16.25"/></svg>
);

export const LaptopReturn = () => (
    <svg className="h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="laptop_return">
    <title id="laptop_return">Laptop Return</title>
    <circle cx="20" cy="20" r="20" className="fill-white dark:fill-black" transform="rotate(-180 20 20)"/>
    <path className="fill-[#0488E1] dark:fill-yellow-5*" fillRule="evenodd" d="M20 .0000035C8.95385.00000157-.00000157 8.95385-.0000035 20-.00000543 31.0462 8.95384 40 20 40c11.0461 0 20-8.9538 20-20C40 8.95385 31.0462.00000543 20 .0000035ZM18.9128 28.7795c.2885.2881.6795.4499 1.0872.4499.4077 0 .7987-.1618 1.0872-.4499l6.1538-6.1539c.1512-.1408.2724-.3106.3565-.4994.0841-.1887.1293-.3924.1329-.599.0037-.2065-.0343-.4117-.1117-.6033-.0774-.1916-.1925-.3656-.3386-.5117-.1461-.1461-.3201-.2612-.5117-.3386-.1916-.0774-.3967-.1154-.6033-.1117-.2066.0036-.4103.0488-.599.1329-.1887.0841-.3586.2053-.4994.3565l-3.5282 3.5282V12.3077c0-.408-.1621-.7993-.4506-1.0879-.2886-.2885-.6799-.4506-1.0879-.4506-.408 0-.7993.1621-1.0879.4506-.2885.2886-.4506.6799-.4506 1.0879v11.6718l-3.5282-3.5282c-.2916-.2718-.6773-.4197-1.0759-.4127-.3986.0071-.7789.1685-1.0607.4504-.2819.2819-.4434.6621-.4504 1.0607-.007.3986.1409.7843.4127 1.0759l6.1538 6.1539Z" clipRule="evenodd"/>
    </svg>
)
export const LaptopCheckOut = () => (
    <svg className="h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="laptop_checkout">
        <title id="laptop_checkout">Laptop Checkout</title>
        <circle cx="20" cy="20" r="20" className="fill-white dark:fill-black"/>
        <path className="fill-[#0488E1] dark:fill-yellow-5*" fillRule="evenodd" d="M20 40c11.0462 0 20-8.9538 20-20C40 8.95384 31.0462-.00000305 20-.00000401 8.95385-.00000498 4.5e-7 8.95384-5.2e-7 20-.00000148 31.0462 8.95384 40 20 40Zm1.0872-28.7795c-.2885-.2881-.6795-.4499-1.0872-.4499-.4077 0-.7987.1618-1.0872.4499l-6.1538 6.1539c-.1512.1408-.2724.3106-.3565.4994-.0841.1887-.1293.3924-.1329.599-.0037.2065.0343.4117.1117.6033.0774.1916.1925.3656.3386.5117.1461.1461.3201.2612.5117.3386.1916.0774.3967.1154.6033.1117.2066-.0036.4103-.0488.599-.1329.1887-.0841.3586-.2053.4994-.3565l3.5282-3.5282v11.6718c0 .408.1621.7993.4506 1.0879.2886.2885.6799.4506 1.0879.4506.408 0 .7993-.1621 1.0879-.4506.2885-.2886.4506-.6799.4506-1.0879V16.0205l3.5282 3.5282c.2916.2718.6773.4197 1.0759.4127.3986-.007.7789-.1685 1.0607-.4504.2819-.2819.4434-.6621.4504-1.0607.007-.3986-.1409-.7843-.4127-1.0759l-6.1538-6.1539Z" clipRule="evenodd"/>
    </svg>
)
export const ProgramOverview = () => (
<svg className="h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" aria-labelledby="program_overview">
    <title id="program_overview">Program Overview</title>
    <circle cx="20" cy="20" r="20" className="fill-[#0488E1] dark:fill-yellow-5*" transform="rotate(-180 20 20)"/>
    <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" d="M11 21.125c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v6.75c0 .621-.504 1.125-1.125 1.125h-2.25c-.2984 0-.5845-.1185-.7955-.3295-.211-.211-.3295-.4971-.3295-.7955v-6.75Zm6.75-4.5c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25c-.2984 0-.5845-.1185-.7955-.3295-.211-.211-.3295-.4971-.3295-.7955v-11.25Zm6.75-4.5c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25c-.2984 0-.5845-.1185-.7955-.3295-.211-.211-.3295-.4971-.3295-.7955v-15.75Z"/>
  </svg>
)
export const Apple = () => (
    <svg className="h-10 w-10 stroke-blue-10* dark:stroke-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none" role="img" aria-labelledby="apple">
        <title id="apple">Apple</title>
        <path strokeWidth="1.6" d="M29.1462 16.1824c-.1393.108-2.5971 1.493-2.5971 4.5725 0 3.5619 3.1275 4.8221 3.2211 4.8533-.0144.0768-.4968 1.7257-1.649 3.4059-1.0273 1.4785-2.1002 2.9547-3.7323 2.9547-1.6322 0-2.0522-.9481-3.9364-.9481-1.8362 0-2.4891.9793-3.982.9793-1.493 0-2.5347-1.3681-3.7324-3.0483-1.3873-1.973-2.5082-5.0381-2.5082-7.9472 0-4.6661 3.0339-7.1407 6.0198-7.1407 1.5865 0 2.9091 1.0417 3.9052 1.0417.948 0 2.4266-1.1041 4.2316-1.1041.684 0 3.1419.0624 4.7597 2.381Zm-5.6166-4.3564c.7465-.8857 1.2745-2.11463 1.2745-3.34355 0-.17042-.0144-.34324-.0456-.48245-1.2145.0456-2.6595.80888-3.5307 1.81938-.6841.77772-1.3226 2.00662-1.3226 3.25232 0 .1872.0312.3744.0456.4345.0768.0144.2017.0312.3265.0312 1.0897 0 2.4602-.7297 3.2523-1.7114Z"/>
    </svg>
);
export const Clock = () => (
    <svg className="h-10 w-10 stroke-blue-10* dark:stroke-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="clock">
        <title id="clock">Clock</title>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M20 14v6h4.5m4.5 0c0 1.1819-.2328 2.3522-.6851 3.4442-.4523 1.0919-1.1152 2.084-1.9509 2.9198-.8358.8357-1.8279 1.4986-2.9198 1.9509C22.3522 28.7672 21.1819 29 20 29c-1.1819 0-2.3522-.2328-3.4442-.6851-1.0919-.4523-2.084-1.1152-2.9198-1.9509-.8357-.8358-1.4986-1.8279-1.9509-2.9198C11.2328 22.3522 11 21.1819 11 20c0-2.3869.9482-4.6761 2.636-6.364C15.3239 11.9482 17.6131 11 20 11s4.6761.9482 6.364 2.636C28.0518 15.3239 29 17.6131 29 20Z"/>
    </svg>
);
export const Document = () => (
    <svg className="h-10 w-10 fill-blue-10* dark:fill-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="document">
        <title id="document">Document</title>
        <path d="M15.9958 11.2704H30.17v18.1551h-2.795V32H12.9145c-.8521 0-1.623-.346-2.1811-.9034-.5581-.558-.90332-1.3297-.90332-2.181 0-.0536.00153-.1064.00383-.1585V11.0806c0-.8475.34679-1.6184.90409-2.17649C11.2961 8.34603 12.067 8 12.9145 8c.8474 0 1.6184.34679 2.1764.90411.5581.55809.9042 1.32819.9042 2.17649v.1906l.0007-.0008Zm3.6716 3.2092h5.8098v1.0205h-5.8098v-1.0205Zm-2.041 10.7162h9.8917v1.0205h-9.8917v-1.0205Zm0-2.1436h9.8917v1.0205h-9.8917v-1.0205Zm0-2.1435h9.8917v1.0205h-9.8917v-1.0205Zm0-2.1435h9.8917v1.0204h-9.8917v-1.0204Zm0-2.1436h9.8917v1.0205h-9.8917v-1.0205Zm8.7281 12.8023H14.9746v-.643c-.0329-.5176-.2565-.9838-.6002-1.3275-.3728-.3728-.8904-.6048-1.4599-.6048-.5696 0-1.0863.2312-1.4599.6048-.4073.4073-.5872.8919-.6048 1.4599 0 .5696.2312 1.0863.6048 1.4599.3728.3728.8895.6048 1.4599.6048h13.44v-1.5541Zm-11.3799-2.8065V11.079c0-.5657-.232-1.08091-.6056-1.4545-.3736-.37359-.8888-.60555-1.4545-.60555-.5658 0-1.0817.23196-1.4546.60555-.3736.37359-.6055.8888-.6055 1.4545v15.5392c.5458-.4907 1.2685-.7893 2.0601-.7893.7916 0 1.5142.2986 2.0601.7885Zm14.1741-14.3273H15.9951v16.1133H29.1495V12.2901h-.0008Z"/>
    </svg>
);
export const Fishbowl = () => (
    <svg className="h-10 w-10 fill-blue-10* dark:fill-yellow-5* stroke-blue-10* dark:stroke-yellow-5*" xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="fishbowl">
        <title id="fishbowl">Fishbowl</title>
        <path strokeWidth=".3" d="M26.7845 8.94082c.1076 0 .2124.03378.2998.09657C30.2402 11.3059 32.15 14.9481 32.15 18.9094c0 6.7103-5.4397 12.15-12.15 12.15s-12.15-5.4397-12.15-12.15c0-3.9612 1.90981-7.6034 5.0657-9.87196.0873-.06279.1922-.09657.2998-.09657l13.569-.00005Zm4.3382 9.96858c0-.8569-.0975-1.6974-.2846-2.5087-.0347-.0103-.0722-.0213-.1126-.0329-.3177-.091-.6876-.1826-1.105-.2684-2.8734-.5904-6.1297-.5904-9.4756.3937-3.5228 1.0361-6.9466 1.0361-9.9722.4144-.39405-.081-.75042-.1673-1.06584-.2547-.15113.7329-.22959 1.4881-.22959 2.2566 0 6.1429 4.97983 11.1227 11.12273 11.1227 6.1429 0 11.1227-4.9798 11.1227-11.1227ZM26.617 9.96809l-13.234.00005c-1.9284 1.42916-3.3312 3.42446-4.02219 5.68906.2978.083.63852.1659 1.01869.244 2.8734.5905 6.1297.5904 9.4756-.3936 3.5228-1.0362 6.9466-1.0361 9.9722-.4144.2401.0493.4663.1007.6777.153-.7297-2.0948-2.0771-3.936-3.888-5.27811ZM14.3955 20.364c0-.3634.367-.6119.7043-.4769l2.1006.8402c.6539-.8076 1.7595-1.2406 3.1632-1.2406 1.2848 0 2.5827.3321 3.5638.8721.9725.5352 1.6771 1.3016 1.6771 2.187 0 .8854-.7046 1.6518-1.6771 2.187-.9811.54-2.279.8721-3.5638.8721-1.4037 0-2.5093-.4331-3.1632-1.2406l-2.1006.8402c-.3373.135-.7043-.1135-.7043-.4769V20.364Zm3.4426 1.2458c-.1267.2237-.3991.3193-.6378.2238l-1.7776-.711v2.8463l1.7776-.711c.2387-.0955.5111.0002.6378.2239.3784.6686 1.2204 1.0958 2.5255 1.0958 1.0632 0 2.131-.2731 2.9294-.6745.3992-.2008.7249-.4306.9488-.6687.2249-.2393.3355-.4741.3355-.6886 0-.2145-.1106-.4493-.3355-.6886-.2239-.2381-.5496-.4679-.9488-.6687-.7984-.4015-1.8662-.6745-2.9294-.6745-1.3051 0-2.1471.4272-2.5255 1.0958Z"/>
    </svg>
);
export const Globe = () => (
    <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="globe">
        <title id="globe">Globe</title>
        <g clip-path="url(#a)">
            <path className="stroke-blue-10* dark:stroke-yellow-5*" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" d="M20.6153 30.7992c2.394-.0002 4.7201-.7953 6.6132-2.2608 1.8931-1.4654 3.2459-3.518 3.846-5.8356m-10.4592 8.0964c-2.394-.0002-4.7201-.7953-6.6132-2.2608-1.8931-1.4654-3.2459-3.518-3.846-5.8356m10.4592 8.0964c2.982 0 5.4-4.836 5.4-10.8s-2.418-10.79998-5.4-10.79998m0 21.59998c-2.982 0-5.4-4.836-5.4-10.8s2.418-10.79998 5.4-10.79998M31.0745 22.7028c.222-.864.3408-1.77.3408-2.7036.003-1.8575-.4754-3.684-1.3884-5.3016m1.0476 8.0052c-3.2002 1.7741-6.8002 2.7022-10.4592 2.6964-3.7944 0-7.3596-.978-10.4592-2.6964m0 0c-.22709-.8832-.3416-1.7916-.34079-2.7036 0-1.926.50399-3.7356 1.38839-5.3016m9.4116-5.49838c1.9155-.0008 3.7967.50803 5.4507 1.47428 1.6538.9662 3.0209 2.3551 3.9609 4.0241m-9.4116-5.49838c-1.9155-.0008-3.7967.50803-5.4507 1.47428-1.6538.9662-3.0209 2.3551-3.9609 4.0241m18.8232 0c-2.6128 2.2631-5.955 3.5066-9.4116 3.5016-3.5976 0-6.888-1.32-9.4116-3.5016"/>
        </g>
        <defs>
            <clipPath id="a">
            <path className="fill-white dark:fill-neutral-10" d="M.615356 0h40v40h-40z"/>
            </clipPath>
        </defs>
    </svg>
);
export const Group = () => (
    <svg className="h-10 w-10 stroke-blue-10* dark:stroke-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="group">
        <title id="group">Group</title>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M25.058 23.146c.4678-.3195 1.0165-.4999 1.5826-.5206.5661-.0206 1.1264.1195 1.6162.404.4898.2846.8891.702 1.1515 1.204.2625.502.3775 1.068.3317 1.6326-1.1998.4193-2.4742.5825-3.741.479-.0039-1.1334-.3303-2.2432-.941-3.198-.5421-.8502-1.2899-1.55-2.1742-2.0345-.8842-.4845-1.8765-.7382-2.8848-.7375-1.0082-.0005-2.0002.2532-2.8843.7377-.8841.4846-1.6317 1.1842-2.1737 2.0343m11.057 3.197.001.031c0 .225-.012.447-.037.666-1.8146 1.0411-3.8709 1.5873-5.963 1.584-2.17 0-4.207-.576-5.963-1.584-.0257-.2314-.0381-.4641-.037-.697m0 0c-1.2664.1073-2.5401-.0553-3.739-.477-.0456-.5644.0694-1.1303.3318-1.6321.2625-.5018.6616-.9191 1.1512-1.2036.4896-.2845 1.0498-.4246 1.6157-.4042.5659.0205 1.1145.2007 1.5823.5199m-.942 3.197c.0036-1.1333.3316-2.2421.942-3.197m8.058-8.772c0 .7956-.3161 1.5587-.8787 2.1213-.5626.5626-1.3256.8787-2.1213.8787-.7956 0-1.5587-.3161-2.1213-.8787-.5626-.5626-.8787-1.3257-.8787-2.1213 0-.7956.3161-1.5587.8787-2.1213.5626-.5626 1.3257-.8787 2.1213-.8787.7957 0 1.5587.3161 2.1213.8787.5626.5626.8787 1.3257.8787 2.1213Zm6 3c0 .2955-.0582.5881-.1713.861-.113.273-.2788.5211-.4877.73-.2089.2089-.457.3747-.73.4877-.2729.1131-.5655.1713-.861.1713s-.588-.0582-.861-.1713c-.273-.113-.5211-.2788-.73-.4877-.2089-.2089-.3746-.457-.4877-.73-.1131-.2729-.1713-.5655-.1713-.861 0-.5967.2371-1.169.659-1.591.422-.4219.9943-.659 1.591-.659s1.169.2371 1.591.659c.422.422.659.9943.659 1.591Zm-13.5 0c0 .2955-.0582.5881-.1713.861-.113.273-.2788.5211-.4877.73-.2089.2089-.457.3747-.73.4877-.2729.1131-.5655.1713-.861.1713s-.588-.0582-.861-.1713c-.273-.113-.521-.2788-.73-.4877-.2089-.2089-.3746-.457-.4877-.73-.1131-.2729-.1713-.5655-.1713-.861 0-.5967.2371-1.169.659-1.591.422-.4219.9943-.659 1.591-.659s1.169.2371 1.591.659c.422.422.659.9943.659 1.591Z"/>
    </svg>
);
export const Infobook = () => (
    <svg className="h-10 w-10 stroke-blue-10* dark:stroke-yellow-5* fill-blue-10* dark:fill-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="infobook">
        <title id="infobook">Infobook</title>
        <path strokeWidth=".1" d="M20 19.3392c-.1753 0-.3435.0696-.4674.1936-.124.124-.1937.2921-.1937.4675v1.8333c0 .1753.0697.3435.1937.4675.1239.124.2921.1936.4674.1936.1754 0 .3435-.0696.4675-.1936s.1936-.2922.1936-.4675v-1.8333c0-.1754-.0696-.3435-.1936-.4675s-.2921-.1936-.4675-.1936ZM20.4711 17.6994l-.0001.0001c.0573.0634.1036.136.1369.2147.0001.0003.0003.0006.0004.001l-.0461.0194c.0343.0725.0511.1522.0489.2324l-.14-.4676Zm0 0-.0033-.003c-.1274-.1176-.2944-.1829-.4677-.1829-.1734 0-.3404.0653-.4678.1829l-.0002-.0002-.0029.0033c-.0578.0635-.1044.1364-.1377.2156-.0338.0795-.0517.1649-.0526.2514v.0005c0 .1753.0697.3435.1937.4675.1239.124.2921.1936.4674.1936.1754 0 .3435-.0696.4675-.1936.1238-.1238.1935-.2917.1936-.4668l-.19-.4683Z"/>
        <path strokeWidth=".1" d="M27.766 9.56133h-.0001C25.0788 9.56465 22.4262 10.1654 20 11.32c-2.4262-1.1546-5.0788-1.75535-7.7659-1.75867H9.61111c-.17534 0-.34349.06965-.46747.19363-.12399.12399-.19364.29214-.19364.46744v17.7223c0 .1753.06965.3435.19364.4674.12398.124.29213.1937.46747.1937h2.62279c2.5929.0032 5.1499.6068 7.4705 1.7636.0061.0031.0118.0047.016.0056.0024.0005.0047.0008.0064.0011.0851.0414.1785.063.2732.063s.1881-.0216.2732-.063c.0017-.0003.004-.0006.0064-.0011.0042-.0009.0099-.0025.016-.0056 2.3206-1.1568 4.8775-1.7604 7.4705-1.7636h2.6228c.1753 0 .3435-.0697.4675-.1937.1239-.1239.1936-.2921.1936-.4674V10.2224c0-.1753-.0697-.34345-.1936-.46744-.124-.12398-.2922-.19363-.4675-.19363H27.766ZM19.3389 24.8906v3.8595c-2.245-.9626-4.6616-1.4615-7.1048-1.4665h-1.9619v-16.4h1.9617c2.4565.0052 4.8819.5493 7.105 1.5939v2.6323c-1.1763.1579-2.2568.7346-3.0429 1.6252-.7957.9015-1.2349 2.0627-1.2349 3.2652 0 1.2026.4392 2.3637 1.2349 3.2653.7861.8905 1.8666 1.4672 3.0429 1.6251Zm8.4271 2.393h-.0001c-2.4432.005-4.8598.5039-7.1048 1.4665v-3.8595c1.1763-.1579 2.2568-.7346 3.0429-1.6251.7957-.9016 1.2349-2.0627 1.2349-3.2653 0-1.2025-.4392-2.3637-1.2349-3.2652-.7861-.8906-1.8666-1.4673-3.0429-1.6252v-2.6323c2.2231-1.0446 4.6485-1.5887 7.105-1.5939h1.9617v16.4H27.766Zm-7.7661-3.6667c-.7152 0-1.4145-.2121-2.0092-.6095-.5948-.3974-1.0583-.9623-1.3321-1.6231-.2737-.6609-.3453-1.3881-.2058-2.0897.1396-.7015.484-1.346.9898-1.8518.5058-.5058 1.1503-.8502 1.8518-.9898.7016-.1395 1.4288-.0679 2.0896.2059.6609.2737 1.2257.7372 1.6231 1.332.3974.5947.6096 1.294.6096 2.0093-.0011.9589-.3825 1.8782-1.0605 2.5562-.678.6781-1.5974 1.0594-2.5563 1.0605Z"/>
    </svg>
);
export const Printer = () => (
    <svg className="h-10 w-10 stroke-blue-10* dark:stroke-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="printer">
        <title id="printer">Printer</title>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M14.72 21.829c-.24.03-.48.062-.72.096m.72-.096c3.5063-.4399 7.0537-.4399 10.56 0m-10.56 0L14.34 26m10.94-4.171c.24.03.48.062.72.096m-.72-.096L25.66 26l.229 2.523c.0142.1557-.0042.3126-.0541.4607-.0498.1482-.13.2843-.2354.3997-.1053.1155-.2336.2077-.3766.2708-.143.0631-.2976.0957-.4539.0958h-9.538c-.662 0-1.18-.568-1.12-1.227L14.34 26m0 0h-1.09c-.5967 0-1.169-.2371-1.591-.659-.4219-.422-.659-.9943-.659-1.591v-6.294c0-1.081.768-2.015 1.837-2.175.6359-.0951 1.2737-.1775 1.913-.247M25.658 26h1.091c.2956.0001.5882-.058.8613-.171.2731-.113.5213-.2787.7303-.4877.2091-.2089.3749-.457.488-.73.1132-.2731.1714-.5657.1714-.8613v-6.294c0-1.081-.768-2.015-1.837-2.175-.6359-.0951-1.2737-.1775-1.913-.247m0 0c-3.4897-.3797-7.0103-.3797-10.5 0m10.5 0v-3.659c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M26 18.5h.008v.008H26V18.5Zm-3 0h.008v.008H23V18.5Z"/>
    </svg>
);
export const Shield = () => (
    <svg className="h-10 w-10 stroke-blue-10* dark:stroke-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="shield">
        <title id="shield">Shield</title>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m17 20.7069 2.25 2.25 3.75-5.25m-3-7.036c-2.2643 2.1502-5.2797 3.3296-8.402 3.286-.3972 1.21-.599 2.4755-.598 3.749 0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z"/>
    </svg>
);
export const Slasheye = () => (
    <svg className="h-10 w-10 stroke-blue-10* dark:stroke-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="slasheye">
        <title id="slasheye">Slasheye</title>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M11.9804 16.223c-.9355 1.1042-1.6321 2.3902-2.04595 3.777 1.29195 4.338 5.30995 7.5 10.06595 7.5.993 0 1.953-.138 2.863-.395m-8.635-12.877c1.7127-1.1302 3.7201-1.7312 5.772-1.728 4.756 0 8.773 3.162 10.065 7.498-.7081 2.3693-2.228 4.4135-4.293 5.774m-11.544-11.544L11.0004 11m3.228 3.228 3.65 3.65m7.894 7.894L29.0004 29m-3.228-3.228-3.65-3.65c.2786-.2786.4996-.6093.6504-.9734.1508-.364.2284-.7541.2284-1.1481 0-.394-.0776-.7841-.2284-1.1481-.1508-.3641-.3718-.6948-.6504-.9734-.2786-.2786-.6093-.4996-.9733-.6504-.364-.1508-.7542-.2284-1.1482-.2284-.394 0-.7841.0776-1.1481.2284-.364.1508-.6948.3718-.9734.6504m4.242 4.242-4.241-4.241"/>
    </svg>
);
export const Tdx = () => (
    <svg className="h-10 w-10 fill-blue-10* dark:fill-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="tdx">
        <title id="tdx">TDX</title>
        <path d="M11.7505 26.5v-1.074l1.3894-.2387v-9.828H10.327l-.2472 1.7218H8.81829v-2.9919H19.1322v2.9919h-1.2445l-.2557-1.7218h-2.8129v9.828l1.3894.2387V26.5h-4.458Zm9.0882 0v-1.074l1.3894-.2387v-9.7769l-1.3894-.2386v-1.0826h4.8586c1.0967 0 2.0514.2614 2.864.7842.8183.5228 1.4548 1.2417 1.9094 2.1566.4546.9149.6819 1.9605.6819 3.1368v.2642c0 1.1479-.2188 2.1821-.6564 3.1027-.4375.9149-1.0598 1.6394-1.8667 2.1736-.8012.5285-1.7502.7927-2.847.7927h-4.9438Zm3.0686-1.3127h1.79c.7842 0 1.4576-.2131 2.0202-.6393.5625-.4318.9944-1.0058 1.2956-1.7218.3069-.7217.4603-1.5201.4603-2.3952v-.2813c0-.9035-.1534-1.7105-.4603-2.4208-.3012-.716-.7331-1.2814-1.2956-1.6962-.5626-.4149-1.236-.6223-2.0202-.6223h-1.79v9.7769Z"/>
    </svg>
);
export const Tools = () => (
    <svg className="h-10 w-10 stroke-blue-10* dark:stroke-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="tools">
        <title id="tools">Tools</title>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m19.4205 23.1676 5.83 5.83c.5.484 1.1702.7521 1.8661.7464.6958-.0056 1.3616-.2846 1.8537-.7766.4921-.4921.771-1.1579.7767-1.8538.0056-.6958-.2625-1.366-.7465-1.866l-5.877-5.877m-3.703 3.797 2.496-3.03c.317-.384.74-.626 1.208-.766.55-.164 1.163-.188 1.743-.14.7818.0671 1.5676-.0714 2.2794-.4017.7118-.3304 1.3248-.8411 1.7782-1.4815.4535-.6405.7316-1.3883.8067-2.1694.0751-.7811-.0553-1.5683-.3783-2.2834l-3.276 3.277c-.5481-.1268-1.0496-.4049-1.4474-.8027-.3978-.3978-.6759-.8992-.8026-1.4473l3.276-3.276c-.7151-.3231-1.5023-.4535-2.2834-.3784-.7811.0752-1.529.3533-2.1694.8067-.6405.4534-1.1512 1.0665-1.4815 1.7783-.3304.7117-.4688 1.4975-.4017 2.2794.091 1.076-.071 2.264-.904 2.95l-.102.085m-.341 5-4.655 5.653c-.2256.275-.5063.4997-.824.6597-.3177.1599-.6654.2516-1.0207.2691-.3553.0175-.7103-.0396-1.0421-.1676-.3319-.128-.6333-.3241-.8848-.5756-.2516-.2515-.4476-.5529-.5756-.8848-.1281-.3319-.1851-.6869-.1677-1.0422.0175-.3553.1092-.7029.2692-1.0206.16-.3177.3847-.5984.6597-.824l6.837-5.63-4.107-4.107h-1.409l-2.25-3.75 1.5-1.5 3.75 2.25v1.409l4.26 4.26-1.745 1.437m8.36 6.769-2.625-2.625m-10.883 3.375h.008v.008h-.008v-.008Z"/>
    </svg>
);
export const Umich = () => (
    <svg className="h-10 w-10 stroke-blue-10* dark:stroke-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="umich">
        <title id="umich">Umich</title>
        <path strokeWidth="1.5" d="m19.9576 25.8586-5.1765-7.0448v5.1269h2.0706V29H7.29407v-5.0616h1.93882v-7.8791H7.29407V11h7.50583l5.1765 7.1077 5.2-7.1077h7.5294v5.0593H30.767v7.8791h1.9388v5.0593h-9.5811v-5.0593h2.0682v-5.1269l-5.2353 7.0471Z"/>
    </svg>
);
export const Wrench = () => (
    <svg className="h-10 w-10 stroke-blue-10* dark:stroke-yellow-5*" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" role="img" aria-labelledby="wrench">
        <title id="wrench">Wrench</title>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M29.752 14.7486c0 .6233-.1294 1.2399-.3802 1.8106-.2507.5707-.6173 1.083-1.0765 1.5046-.4592.4216-1.0009.7431-1.5909.9443-.59.2012-1.2154.2776-1.8364.2245-1.076-.091-2.264.071-2.95.904l-7.152 8.684c-.2256.275-.5063.4997-.824.6597-.3177.1599-.6654.2516-1.0207.2691-.3553.0175-.7103-.0396-1.0422-.1676-.3318-.128-.6332-.3241-.8848-.5756-.2515-.2515-.4476-.5529-.5756-.8848-.128-.3319-.1851-.6869-.1676-1.0422.0175-.3553.1092-.7029.2692-1.0206.1599-.3177.3847-.5984.6597-.824l8.684-7.152c.833-.686.995-1.874.904-2.95-.0672-.7819.0713-1.5677.4017-2.2794.3303-.7118.841-1.3249 1.4815-1.7783.6404-.4534 1.3883-.7315 2.1694-.8067.7811-.0751 1.5682.0553 2.2834.3784l-3.276 3.276c.1267.548.4048 1.0495.8026 1.4473.3978.3978.8993.6759 1.4474.8027l3.276-3.276c.256.565.398 1.192.398 1.852Z"/>
        <path stroke="#0D57AA" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M13.0144 26.978h.008v.008h-.008v-.008Z"/>
    </svg>
);